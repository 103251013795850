/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Gallery.scss';
import i1 from './../../images/ecosse/fall.jpg';
import i2 from './../../images/ecosse/IMG_2188.jpg';
import i3 from './../../images/ecosse/IMG_2218.jpg';
import i4 from './../../images/ecosse/IMG_2313.jpg';
import i5 from './../../images/ecosse/IMG_2364.jpg';
import i6 from './../../images/ecosse/IMG_2535.jpg';
import i7 from './../../images/ecosse/IMG_2621.jpg';
import i8 from './../../images/ecosse/IMG_2771.jpg';
import i9 from './../../images/ecosse/IMG_3013.jpg';
import i10 from './../../images/ecosse/IMG_3082.jpg';
import i11 from './../../images/ecosse/IMG_3300.jpg';
import i12 from './../../images/ecosse/IMG_4075-Edit.jpg';
import i13 from './../../images/ecosse/IMG_4169.jpg';
import i14 from './../../images/ecosse/IMG_4405.jpg';
import i15 from './../../images/ecosse/IMG_4461.jpg';
import i16 from './../../images/ecosse/IMG_4465.jpg';
import i17 from './../../images/ecosse/IMG_4467.jpg';
import i18 from './../../images/ecosse/IMG_4469.jpg';
import i19 from './../../images/ecosse/IMG_4480.jpg';

const GalleryEcosse = () => {
  const images = [
    i1, i2, i3, i4, i5, 
    i6, i7, i8, i9, i10, i11, i12, i13, 
    i14, i15, i16, i17, i18, i19
  ];

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={index} className="image-container imageLoader">
          <LazyLoadImage
            effect='blur'
            src={image}
          />
        </div>
      ))}
    </div>
  );
};

export default GalleryEcosse;
