import './Menu.scss';
import { ReactComponent as BurgerMenu } from '../../images/icons/burger-menu.svg';
import { ReactComponent as Instagram } from '../../images/icons/instagram.svg';
import { ReactComponent as Facebook } from '../../images/icons/facebook.svg';
import React, { useState } from 'react';

const Menu = () => {

  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  const closeShowNavbar = () => {
    setShowNavbar(false)
  }
  
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <div class='logo-img'></div>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <BurgerMenu className="burger-icon"/>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <a href="/" onClick={() => closeShowNavbar()}>A propos</a>
            </li>
            <li>
              <a href="/portfolio" onClick={() => closeShowNavbar()}>Portfolio</a>
            </li>
            <li>
              <a href="/materiel_photo" onClick={() => closeShowNavbar()}>Materiel Photo</a>
            </li>
          </ul>
          {showNavbar && (
          <div className='showbar-social-medias-list'>
            <a href='https://www.instagram.com/admarquant'>
              <Instagram className='social-media'></Instagram>
            </a>
            <a href='https://www.facebook.com/profile.php?id=100089297026833'>
              <Facebook className='social-media'></Facebook>
            </a>
          </div>
        )}
        </div>
        <div className={`nav-elements`}>
          <a href='https://www.instagram.com/admarquant'>
            <Instagram className='social-media'></Instagram>
          </a>
          <a href='https://www.facebook.com/profile.php?id=100089297026833'>
            <Facebook className='social-media'></Facebook>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Menu

