import './Home.scss';
import Card from '../../components/card/Card';

function Home() {

  return (
    <div className="Home">
      <div className='home-header'>
        <span className='name'>Arnaud Marquant <br/> Photographe</span>
        <div className="bienvenue"></div>
      </div>
      
      <Card/>
    </div>
  );
}

export default Home;
