/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Gallery.scss';
import rome from './../../images/italie/rome.jpg';
import rome_2 from './../../images/italie/rome_2.jpg';
import colisée from './../../images/italie/colisée.jpg';
import fontaine from './../../images/italie/fontaine.jpg';
import colisée_2 from './../../images/italie/colisée_2.jpg';
import toscane from './../../images/italie/toscane.jpg';
import toscane_2 from './../../images/italie/toscane_2.jpg';
import toscane_3 from './../../images/italie/toscane_3.jpg';
import toscane_4 from './../../images/italie/toscane_4.jpg';
import toscane_5 from './../../images/italie/toscane_5.jpg';
import toscane_6 from './../../images/italie/toscane_6.jpg';
import toscane_7 from './../../images/italie/toscane_7.jpg';
import toscane_8 from './../../images/italie/toscane_8.jpg';
import venise_1 from './../../images/italie/venise_1.jpg';
import venise_2 from './../../images/italie/venise_2.jpg';
import venise_3 from './../../images/italie/venise_3.jpg';
import venise_4 from './../../images/italie/venise_4.jpg';
import venise_5 from './../../images/italie/venise_5.jpg';
import venise_6 from './../../images/italie/venise_6.jpg';
import venise_7 from './../../images/italie/venise_7.jpg';
import venise_8 from './../../images/italie/venise_8.jpg';
import venise_9 from './../../images/italie/venise_9.jpg';
import venise_11 from './../../images/italie/venise_11.jpg';
import venise_12 from './../../images/italie/venise_12.jpg';

const GalleryItalie = () => {
  const images = [
    rome, rome_2, fontaine, colisée_2, 
    toscane, toscane_2, toscane_3, toscane_4,  colisée, toscane_5, toscane_6, toscane_7, toscane_8, 
    venise_1, venise_2, venise_3, venise_4, venise_5, venise_6, venise_7, venise_8, venise_9, venise_11, venise_12 
  ];

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={index} className="image-container imageLoader">
          <LazyLoadImage
            effect='blur'
            src={image}
          />
        </div>
      ))}
    </div>
  );
};

export default GalleryItalie;
