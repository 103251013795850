/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Gallery.scss';
import photo from './../../images/new-york/background.jpg';
import bridge from './../../images/new-york/bridge.jpg';
import ants from './../../images/new-york/ants.jpg';
import bricks from './../../images/new-york/bricks.jpg';
import bridgeSunset from './../../images/new-york/bridge-sunset.jpg';
import empire from './../../images/new-york/empire.jpg';
import road from './../../images/new-york/road.jpg';
import skywalk from './../../images/new-york/skywalk.jpg';
import spine from './../../images/new-york/spine.jpg';
import vessel from './../../images/new-york/vessel.jpg';
import liberty from './../../images/new-york/liberty.jpg';
import food from './../../images/new-york/food.jpg';
import ants2 from './../../images/new-york/ants-2.jpg';
import chrysler from './../../images/new-york/chrysler.jpg';

const GalleryNewYork = () => {
  const images = [photo, bridge, bricks, ants, ants2, empire, bridgeSunset, road, skywalk, spine, vessel, liberty, chrysler, food];

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={index} className="image-container imageLoader">
          <LazyLoadImage
            effect='blur'
            src={image}
          />
        </div>
      ))}
    </div>
  );
};

export default GalleryNewYork;
