import './Card.scss';

export default function Card() {
  
  return (
    <div className="card-comp">

      <div className='card-container'>
        <div className='me'></div>
        <div className='text'>
          <h2>
            Bonjour, 
           <br/> Je m'appelle Arnaud Marquant, je suis photographe d'aventures et de voyages.
          </h2>
          <br/>
          <h2>
            J'ai découvert ma passion pour la photographie il y a plusieurs années. 
            <br/> Et depuis, je n'ai pas lâché mon appareil.
          </h2>
          <br/>
          <h2>
            J'adore voyager et je profite de la beauté de mes voyages pour perfectionner mes photos.
          </h2>
          <br/>
          <h2>
            Étant ingénieur informatique de formation, j'ai décidé de combiner mes deux passions afin de créer ce portfolio.
          </h2>
        </div>
      </div>
      
    </div>
  );
}

