/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Gallery-Menu.scss';

const GalleryMenu = () => {
  const cards = [
    { title: 'New York', copy: 'La grande pomme a été mon tout premier modèle. Un vrai terrain de jeu infini.', button: 'Voir photos', href: 'portfolio/new-york' },
    { title: 'Italie', copy: 'Ce pays regorge de couleurs.', button: 'Voir photos', href: 'portfolio/italie' },
    { title: 'Ecosse', copy: 'Les plus beaux paysages qu\'il m\'a été donné de voir.', button: 'Voir photos', href: 'portfolio/ecosse' },
    { title: 'Urbex', copy: 'La nature reprendra toujours le pas sur la création de l\'Homme.', button: 'Voir photos', href: 'portfolio/urbex' }
  ];

  return (
    <div className="page-content">
      {cards.map((card) => (
        <a key={card.title} href={card.href} className="card">
            <div className="content">
                <h2 className="title">{card.title}</h2>
                <p className="copy">{card?.copy}</p>
            </div>
        </a>
      ))}
    </div>
  );
};

export default GalleryMenu;
