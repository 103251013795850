import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Menu from './components/menu/Menu';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Album from "./pages/album/Album";
import GalleryNewYork from './components/gallery/Gallery-NewYork';
import GalleryItalie from './components/gallery/Gallery-Italie';
import GalleryEcosse from './components/gallery/Gallery-Ecosse';
import GalleryUrbex from './components/gallery/Gallery-Urbex';
import Home from './pages/home/Home';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Menu/>
    <div class='red rectangle top first'></div>
    <div class='orange rectangle top second'></div>
    <div class='blue rectangle top third'></div>
    <div class='scroll'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Album />} />
        <Route path="/portfolio/new-york" element={<GalleryNewYork />} />
        <Route path="/portfolio/italie" element={<GalleryItalie />} />
        <Route path="/portfolio/ecosse" element={<GalleryEcosse />} />
        <Route path="/portfolio/urbex" element={<GalleryUrbex />} />
      </Routes>
    </div>
    
    <div class='blue rectangle bottom third'></div>
    <div class='orange rectangle bottom second'></div>
    <div class='red rectangle bottom first'></div>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
