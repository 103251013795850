/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Gallery.scss';
import i1 from './../../images/urbex/IMG_1490.jpg';
import i2 from './../../images/urbex/IMG_1589.jpg';
import i3 from './../../images/urbex/IMG_1732.jpg';
import i4 from './../../images/urbex/IMG_2000.jpg';
import i5 from './../../images/urbex/IMG_2110.jpg';
import i6 from './../../images/urbex/IMG_2127.jpg';

const GalleryUrbex = () => {
  const images = [
    i1, i2, i3, i4, i5, 
    i6
  ];

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={index} className="image-container imageLoader">
          <LazyLoadImage
            effect='blur'
            src={image}
          />
        </div>
      ))}
    </div>
  );
};

export default GalleryUrbex;
