import './Album.scss';
import GalleryMenu from '../../components/gallery-menu/Gallery-Menu';

export default function Album() {

  return (
    <div className="app">
      <GalleryMenu></GalleryMenu>
    </div>
  );
}
